import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { VideoAssetsInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-race-videos',
  templateUrl: './race-videos.component.html',
  styleUrls: ['./race-videos.component.scss'],
})
export class RaceVideosComponent {
  @Input() videoAssetsInfo!: VideoAssetsInfo[];
  isMuted = true;
  translationData: any;
  emotionalAnimationIndex: number = -1;
  faceCamAssetIndex: number = -1;
  emotionalVideoIndex: number = -1;
  videoResponse: any;
  featureFlags: any;
  isDownloadButtonVisible: boolean = false;
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;


  constructor(private translationService: TranslationService) {
    this.featureFlags = this.translationService.featureFlags;
    this.isDownloadButtonVisible = this.featureFlags.general.enableDownloadAllButton;
   }

  ngOnInit() {
    this.translationService
      .getTranslationDataAsObservable()
      .subscribe((translationData: any) => {
        this.translationData = translationData;
      });
    this.getEmotionalVideoOnLoad();
    this.loadFaceCamAssetOnLoad();
    this.loadEmotionalAnimationAssetOnLoad();
    this.videoResponse = this.videoAssetsInfo;
  }


  ngAfterViewInit(): void {
    const video = this.videoElement.nativeElement;
    video.addEventListener('loadeddata', () => {
      video.currentTime = 0.01; // Seek to 0.01 seconds
      video.play();
      setTimeout(() => {
        video.pause();
      }, 1000); 
    });
  }

  downloadVideo(videoUrl: any, name: string) {
    const url = videoUrl;
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = url;
    document.body.appendChild(anchor); // Append to the body
    anchor.click();
    document.body.removeChild(anchor); // Remove from the body
  }

  public async downloadAllVideos(): Promise<void> {
    const video1 = this.videoAssetsInfo[this.faceCamAssetIndex]?.url;
    const video2 = this.videoAssetsInfo[this.emotionalVideoIndex]?.url;
    const video3 = this.videoAssetsInfo[this.emotionalAnimationIndex]?.url;

    if (video1) {
      this.downloadVideo(video1, 'face_cam.mp4');
      await this.delay(1000); // 1 second delay
    }
    if (video2) {
      this.downloadVideo(video2, 'emotional_video.mp4');
      await this.delay(1000); // 1 second delay
    }
    if (video3) {
      this.downloadVideo(video3, 'emotional_animation.mp4');
      await this.delay(1000); // 1 second delay
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  public loadEmotionalAnimationAssetOnLoad() {
    this.emotionalAnimationIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'EMOTIONAL_ANIMATION'
    );
  }

  public getEmotionalVideoOnLoad() {
    this.emotionalVideoIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'EMOTIONAL_VIDEO'
    );
  }

  public loadFaceCamAssetOnLoad() {
    this.faceCamAssetIndex = this.videoAssetsInfo.findIndex(
      (video: VideoAssetsInfo) => video.type === 'FACECAM'
    );
  }
}
