
<div id="d-dom"></div>
<section id="profile-l-panel">
    <div class="profile-wrapper">
        <div class="profile">
            <div class="profile-group-primary">
                <div class="profile-picture">
                    <img class="profile-image" style="background-image: url({{profileDetails.profileImageUrl?profileDetails.profileImageUrl:profileImage}});" alt="">
                </div>
                <div class="profile-name">
                    <h6>{{displayName}}</h6>
                    <small *ngIf="profileDetails?.email!=null && profileDetails.isOwner" >{{profileDetails.email}}</small>
                    <div class="prof-links-main">
                        <span (click)="profileClicked()" class="prof-link" *ngIf="profileDetails.isOwner">{{'CP_SM_GARAGE_EDIT_PROFILE' | customTranslation: translationData}}</span>
                        <span class="prof-link" *ngIf="profileDetails.isOwner  && isShareProfileVisible" (click)="shareProfileClicked()">{{'CP_SM_GARAGE_SHARE_PROFILE' | customTranslation: translationData}}</span>
                    </div>
                </div>
            </div>
            <div class="profile-group-secondary">
                <div class="prof-info about"  *ngIf='!!profileDetails?.description'>
                    <h6>{{'CP_SM_GARAGE_ABOUT' | customTranslation: translationData}}</h6>
                    <p>{{profileDetails.description}}</p>
                </div>
                <div class="prof-info social" *ngIf="!!profileDetails?.socialLink?.length">
                    <h6>{{'CP_SM_GARAGE_SOCIAL' | customTranslation: translationData}}</h6>
                <span class="social-link" (click)="socialLinkClicked()" >{{profileDetails.socialLink}}</span>
                </div>
            </div>
        </div>
    </div>
</section>
