<div id="race-share-main" *ngIf="isShowSpinner && !isAccessDenied">

  <div id="back-btn-reg-rshr" *ngIf="isBackButtonVisible && showBackBtnForLoggedInUser" (click)="navigateToMyStories()">
    <button class="trans">
      <span class="icon icon_back"> </span>
    </button>
  </div>
  <div id="race-share-container" *ngIf="raceSharePageInfo">
    <section *ngIf="showEventDetails">
      <app-event-details [exercisename]="exercisename" [eventDetails]="raceSharePageInfo"
        [raceInfo]="raceSharePageInfo.raceInfo"></app-event-details>
    </section>
    <section *ngIf="showPublishButton">
      <div class="publish-btn">
        <button class="publish-button" *ngIf="!isHidden" (click)="publishExp()"><span>{{'RSP_INFO_PUBLISH_TITLE' | customTranslation: translationData}}</span></button>
      </div>
    </section>
    <section *ngIf="raceSharePageInfo.videoAssetsInfo && showHighlightVideo">
      <app-highlight-video
        [videoAssetsInfo]="raceSharePageInfo.videoAssetsInfo"></app-highlight-video>
    </section>
    <section *ngIf="showTelemetricCard">
      <app-telemetric-data-card [raceInfo]="raceSharePageInfo.raceInfo" [isOffroad]="isOffroad" [isbestTime]="isBestTimeChallenge"
        [isDriftChallenge]="isDriftChallenge"></app-telemetric-data-card>
    </section>
    <section>
      <div id="rc-trk-veh-main" class="rc-shr-pnl">
        <div class="rc-shr-title not-mobile">
          <h1 *ngIf="!isTrackError">{{'RSP_TRACK_AND_VEHICLE' | customTranslation: translationData}}</h1>
          <h1 *ngIf="isTrackError">{{'RSP_VEHICLE' | customTranslation: translationData}}</h1>
        </div>
        <div class="track-veh-box">
          <div class="rc-shr-title for-mobile"  *ngIf="!isTrackError">
            <h1>{{'CP_LM_LANDINGPAGE_TRACKS' | customTranslation: translationData}}</h1>
          </div>
          <div class="track-veh-item" *ngIf="!isTrackError && showEventTrack">
            <app-event-track-detail *ngIf="trackInfo" [trackDesc]="raceSharePageInfo.trackInfo"
              [trackDetailsInfo]="trackInfo" [raceItemType]="raceSharePageInfo.itemType"></app-event-track-detail>

          </div>
          <div class="rc-shr-title for-mobile">
            <h1>{{'RSP_VEHICLE' | customTranslation: translationData}}</h1>
          </div>
          <div class="track-veh-item" *ngIf="showCarDetails">
            <app-event-car-details [vehicleInfo]="raceSharePageInfo.vehicleInfo"></app-event-car-details>
          </div>
        </div>
      </div>
    </section>
    <!-- <section *ngIf="isLeaderBoardVisible">
            <div class="rc-shr-pnl" >
                <div class="rc-shr-title">
                    <h1 >Leaderboard</h1>
                    <a role="button">Details</a>
                </div>
                <div class="leader-board-list-min">

                   <div class="lb-score-bar-item gold">
                        <div class="pos-avatar">
                            <span class="rc-pos">1</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                   </div>

                   <div class="lb-score-bar-item silver">
                        <div class="pos-avatar">
                            <span class="rc-pos">2</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item bronze">
                        <div class="pos-avatar">
                            <span class="rc-pos">3</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item usr">
                        <div class="pos-avatar">
                            <span class="rc-pos">4</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name User</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>

                    <div class="lb-score-bar-item"></div>
                    <div class="lb-score-bar-item"></div>

                    <div class="lb-score-bar-item">
                        <div class="pos-avatar">
                            <span class="rc-pos">199</span>
                            <div class="avtr-box">
                                <figure class="rc-avtr">
                                    <img src="../../../../../assets/images/profile-fallback.png" >
                                </figure>
                            </div>
                        </div>
                        <div class="rcr-info-main">
                            <div class="name">
                                <h6>Racer Name</h6>
                            </div>
                            <div class="scr-bar">
                                <span class="rc-time">1:44.22 min</span>
                                <span class="rc-diff">+ 0.21 s</span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </section> -->
        <section *ngIf="showRaceVideos">
            <app-race-videos [videoAssetsInfo]="raceSharePageInfo.videoAssetsInfo"></app-race-videos>
        </section>
        <section *ngIf="imageAssetsInfo && imageAssetsInfo.length > 0">
          <app-event-impressions [images]="imageAssetsInfo"></app-event-impressions>
       </section>


  </div>

</div>
<div *ngIf="showPublishButton">
  <div id="publishing-event-race" *ngIf="isShowSpinner && !isAccessDenied">
    <app-publishing-race-exp class="full-bg" *ngIf= "subType==='EVENT' && !isPublished" [id]="id" [vinOrFin]="vinOrFin" [subType]="subType" > </app-publishing-race-exp>
  </div>
</div>


<div *ngIf="!isShowSpinner">
  <app-spinner-component></app-spinner-component>
</div>

<div *ngIf="isShowSpinner"
  [ngClass]="(isOffroad ) ? 'dummy-page-feel-offroad':'dummy-page-feel' ">
</div>
<div *ngIf="isAccessDenied">
  <app-race-share-error-handling></app-race-share-error-handling>
</div>


<!-- <ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container> -->
