import { Component, Input } from '@angular/core';
import { Auth } from 'aws-amplify';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-response-page',
  templateUrl: './response-page.component.html',
  styleUrls: ['./response-page.component.scss']
})
export class ResponsePageComponent {
  @Input() success:any;
  @Input() failure:any;
  @Input() info:any;
  @Input() infoMessageAlert:any;
  @Input() responseMessage: any;
  @Input() responseInfoMessage:any
  translationData = [];
  showLogoutOption: boolean = false;
  constructor(private translationService: TranslationService, private _router: Router){
  }

  auth = Auth;

  ngOnInit(): void {

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  ngOnChanges(): void {
    this.showLogoutOption = this.responseMessage === 'CP_SM_USER_NOT_FOUND'
  }

  async logout() {
    const currentRoute = this._router.url;
    localStorage.setItem('routeUrl', currentRoute)
    const logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('currentCIAMUser');
    localStorage.removeItem('token');
    await this.auth.signOut()
      .then((_data) => {
        setTimeout(()=> {
          window.open(logoutUrl, "_self");
        }, 1000);
      })
  }
}
