<div class="rc-shr-pnl">
  <div class="rc-shr-title">
    <h1>{{ "RSP_IMPRESSIONS" | customTranslation : translationData }}</h1>
    <a role="button" (click)="downloadMedia()" *ngIf="isDownloadButtonVisible">{{ "RSP_DOWNLOAD_ALL" | customTranslation
      : translationData }}</a>
  </div>

  <div class="containers">
    <div class="grid">
      <div class="grid-item" *ngFor="let image of images; let i = index">
        <app-content-filtering *ngIf="isMediaSensitive(image.isSensitive!)" [sensitiveImage]="image.url!"
          (setMediaSensitive)="setMediaSensitive($event, i)" [isImpressionAvailable]="true">
        </app-content-filtering>
        <img [src]="image.url" alt="Image" [ngClass]="image.isSensitive?'imageSensitive':''" />
      </div>
    </div>
  </div>
</div>
