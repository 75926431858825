import { Media } from './../../../../../components/interfaces/myStories.interface';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService, ToastType } from 'src/app/services/toast.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {
  @Input() eventBanner = "";
  defaultBanner = "../../../assets/mercedes1.png";
  volume: boolean = false;
  // Assuming an array of media file URLs
  @Input() itemType : string = '';
  @Input() numberOfImages: number = 0;
  public currentIndex: number = 0;
  date: any;
  @Input() story !: any;
  isPublished: boolean = false;
  raceStartTime: any;
  translationData = [];
  driftScore!: number;
  language: string = '';
  offroadTitle: string = 'CP_SM_OFFROAD_TITLE';
  selectedLanguage = 'en-GB';


    constructor(private el: ElementRef,
      private translationService: TranslationService,
      private router: Router,
      private _toastService: ToastService
    ) { }

    ngOnInit(): void {
      this.translationService.getTranslationDataAsObservable().subscribe(
        (translationData: any) => {
          this.translationData = translationData;
        }
      )

      this.date = new Date(this.story?.createdAt *1000);
      this.driftScore = this.story?.raceData?.driftScore;
      this.raceStartTime = new Date(this.story?.raceData?.raceStartTime  *1000);
      this.selectedLanguage = localStorage.getItem('language') || 'en-GB';

    }

    scroll(direction: number) {
      this.currentIndex = (this.currentIndex + direction + this.story.media.length) % this.story.media.length;
      const mediaContainer = this.el.nativeElement.querySelector('.media-container');
      if (mediaContainer ) {
        const scrollTo = this.currentIndex * mediaContainer.clientWidth;
        mediaContainer.scrollLeft = scrollTo;
      }
    }


    convertToTotalTime(value: number): string {
      let totalSeconds: number;
      if (value >= 1000) {
        // Convert milliseconds to seconds
        totalSeconds = value / 1000;
      } else {
        // Use the provided value as seconds
        totalSeconds = value;
      }

      let minutes: number = Math.floor(totalSeconds / 60);
      let remainingSeconds: number = totalSeconds % 60;
      let roundedSeconds: string = remainingSeconds.toFixed(2); // Round seconds to two decimal places

      // Format the output
      return `${minutes}:${roundedSeconds}`;
    }

  public naviagateToExperience(): void {
    const isMediaUploadSucess = this.story.media.every((mediaFile: any) => 'SUCCESS' === mediaFile.status)
    if (isMediaUploadSucess) {
      this.router.navigateByUrl(`${this.selectedLanguage}/stories/experience/${this.story.storyId}`);
    } else {
      this._toastService.show(ToastType.Error, 'CP_SM_ASSET_GENERAYION_INPROGRESS', '8%', '40%');
      setTimeout(() => {
        this._toastService.hide();
      }, 4000);
    }
  }

    stopPropagation(event: Event) {
      event.stopPropagation();
    }

    public getDriftScore(): string {
      const locale = this.selectedLanguage.split('-')[0];
      const formatLangList = ['de', 'es', 'fr', 'cs'];
      const driftScore = this.story.raceData.driftScore;

      if (formatLangList.includes(locale)) {
        // For locales that use a period as a thousand separator
        return driftScore.toLocaleString('de-DE'); // German locale as an example
      } else {
        // For locales that use a comma as a thousand separator
        return driftScore.toLocaleString('en-GB'); // British English locale as an example
      }
    }

    isMediaSensitive(isMediaSensitiveKey: boolean): boolean {
      return isMediaSensitiveKey;
    }

    setMediaSensitive(event: boolean, index: number): void {
      this.story.media[index].isSensitive = event;
    }

}
