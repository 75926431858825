import { Component, Input, OnInit } from '@angular/core';
import { BusinessOwnerInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-event-owner-details',
  templateUrl: './event-owner-details.component.html',
  styleUrls: ['./event-owner-details.component.scss']
})
export class EventOwnerDetailsComponent implements OnInit{

@Input() experienceOwner!: string;
@Input() profileInitials!: string;
@Input() businessOwner!: BusinessOwnerInfo;
@Input() profileImage: any;
@Input() subType!: string;
@Input() itemType!: string;
businessOwnerInitials!:  string;
translationData: any;
businessOwnerName!: string;
translationString!: string;
constructor(private translationService: TranslationService){

}
ngOnInit() : void{

    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  this.businessOwnerName = this.businessOwner?.Name ? this.businessOwner?.Name: '';
  if(this.businessOwner?.Name){
    const parts = this.businessOwner?.Name.split(/[\s\-]+/); // Split by space or hyphen
    this.businessOwnerInitials = '';
   if (parts.length >= 2) {
     this.businessOwnerInitials= parts[0].charAt(0).toUpperCase() + parts[parts.length-1].charAt(0).toUpperCase();
   } else {
    this.businessOwnerInitials = parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase();

   }
  }
  if(this.experienceOwner){
    const parts = this.experienceOwner.split(/[\s\-]+/); // Split by space or hyphen
    this.profileInitials = '';
   if (parts.length >= 2) {
    this.profileInitials= parts[0]?.charAt(0)?.toUpperCase() + parts[parts.length-1]?.charAt(0)?.toUpperCase();
   } else {
     this.profileInitials = parts[0]?.charAt(0)?.toUpperCase() + parts[1]?.charAt(0)?.toUpperCase();
   }
  }
}

isBusinessOwnerEmpty(): boolean {
  return !this.businessOwner || Object.keys(this.businessOwner).length === 0;
}

}
