import { routes } from './../../../../app-routing.module';
import { Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MyStoriesService } from '../../services/my-stories.service';
import { catchError, of, Subject, switchMap, takeUntil } from 'rxjs';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-car-garage',
  templateUrl: './car-garage.component.html',
  styleUrls: ['./car-garage.component.scss']
})
export class CarGarageComponent implements OnInit,OnDestroy {
  media: any;
  vehicleUrl: any;
  selectedInfo: any;
  @Output() pageNumber = new EventEmitter<any>();
  @Output() vehicleVinOfCar = new EventEmitter<any>();
  public currentIndex: number = 0;
  @Input() garageDetails: any;
  mainCar: boolean = false;
  carDetails: any;
  isSelected: boolean = false;
  vehicleVin: any;
  myStory: any;
  myStoryCount: boolean = false;
  isLoading: boolean = false;
  defaultCarImage='../../../../../assets/images/car-fallback.png';
  defaultCarImageFall = '../../../../../assets/images/car-fallback-front.png';
  imageLoaded = false;   imageFailed = false;
  @Input() ciamId: any;
  @Input() vinOrFinFromRoutes: any;
  map = new Map<any, any>();
  page = 0;
  private fetchStoiesForCar = new Subject();
  private destroy$ = new Subject();
  translationData = [];
  language: string = '';
  @Input() isMinCardActive: boolean = false;
  @ViewChild("scrollElement") scrollElement: ElementRef | any;

  constructor(private el: ElementRef,
    private myStories: MyStoriesService,
    private translationService: TranslationService,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.fetchStoiesForCar.pipe(
      switchMap((params: any) =>{return this.myStories.getStroiesForVin(params.vehicleVin, this.ciamId, params.page, 10).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.error['error-code'] === '9012'|| error.error['error-code']==='2005'){
              this.router.navigateByUrl('/'+this.language+'/404');
            }
            return of(null); // or EMPTY
        })
      )} ),
      takeUntil(this.destroy$) // This will ensure the subscription is cancelled when the component is destroyed
    ).subscribe((data: any) => {
      this.pageNumber.emit(data.total_pages);
      data.stories.forEach((story: any) => {
        let date = new Date(story.createdAt * 1000);
        let dateYear = date.getFullYear()
        // if the map has year already then it will just append the story value to the current map
        if (this.map.has(dateYear)) {
          const storiesList = this.map.get(dateYear);
          storiesList.push(story);
          this.map.set(dateYear, storiesList);

        }
        // else it will add year and value
        else {
          this.map.set(dateYear, [story]);

        }
        // reversing the map
        let storyMap = new Map([...this.map].reverse());
        this.myStory = storyMap;
      });
      if (this.myStory === undefined) this.myStoryCount = true;
      this.isLoading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['garageDetails']) {
    this.isLoading = true;
    this.carDetails = this.garageDetails.garage;
    if (this.vinOrFinFromRoutes?.length) {
      this.vehicleVin = this.vinOrFinFromRoutes
      this.vehicleVinOfCar.emit(this.vehicleVin);
      this.currentIndex = this.carDetails.findIndex((carDetail: { vinOrFin: string | null; }) => carDetail.vinOrFin === this.vinOrFinFromRoutes);
      const carInfo = this.carDetails.filter((carDetail: { vinOrFin: string | null; }) => carDetail.vinOrFin === this.vinOrFinFromRoutes);
      this.selectedCarInfo(carInfo[0]);
      this.fetchStoriesForVin(this.vehicleVin, this.page);
    }
    else {
      this.vehicleVin = this.garageDetails?.garage[0].vinOrFin;
      this.vehicleVinOfCar.emit(this.garageDetails?.garage[0].vinOrFin);
      this.fetchStoriesForVin(this.vehicleVin, this.page);
      this.selectedCarInfo(this.carDetails[0]);
    }
    }
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

  fetchStoriesForVin(vehicleVin: string, page: number) {
    this.fetchStoiesForCar.next({ vehicleVin, page });
  }

  moveLeftButton(vinOrFin: string, carDetails: any) {
    this.isLoading = true;
    this.map = new Map<any, any>();
    this.vehicleVin = vinOrFin;
    this.vehicleVinOfCar.emit(this.vehicleVin);
    this.selectedCarInfo(carDetails);
    this.fetchStoriesForVin(vinOrFin, this.page);
    this.currentIndex = (this.currentIndex - 1 + this.carDetails.length) % this.carDetails.length;
    this.scrollTop();

  }

  moveRightButton(vinOrFin: string, carDetails: any) {
    this.isLoading = true;
    this.map = new Map<any, any>();
    this.vehicleVin = vinOrFin;
    this.vehicleVinOfCar.emit(this.vehicleVin);
    this.selectedCarInfo(carDetails);
    this.fetchStoriesForVin(vinOrFin, this.page);
    this.currentIndex = (this.currentIndex + 1) % this.carDetails.length;
    this.scrollTop();
  }

  currentSlide(slideIndex: number, vinOrFin: string, carDetails: any) {
    this.isLoading = true;
    this.map = new Map<any, any>();
    this.currentIndex = slideIndex;
    this.vehicleVin = vinOrFin;
    this.vehicleVinOfCar.emit(this.vehicleVin);
    this.selectedCarInfo(carDetails);
    this.fetchStoriesForVin(this.vehicleVin, this.page);
  }

  /**
   * @description This function is called when the user swipes left in the carousel
   * @param vinOrFin 
   * @param carDetails 
   * @param index 
   */
  public onSwipeLeft(vinOrFin: string, carDetails: any, index: number): void {
    if (index !== this.carDetails?.length - 1) {
      this.moveRightButton(vinOrFin, carDetails);    
    }
  }

  /**
   * @description This function is called when user swipes right on the carousel
   * @param vinOrFin 
   * @param carDetails 
   * @param index 
   */
  public onSwipeRight(vinOrFin: string, carDetails: any, index: number): void {
    if (index !== 0) {
      this.moveLeftButton(vinOrFin, carDetails)
    }
  }

  public onSwipeUp(): void {
    window.scrollBy(0, 500);
  }

  public onSwipeDown(): void {
    window.scrollBy(0, -500);
  }

  handleImageError(event: any) {
    event.target.src = '../../../../../assets/images/car-fallback.png';
  }

  handleFrontImageError(event: any) {
    event.target.src = '../../../../../assets/images/car-fallback-front.png';
  }


  selectedCarInfo(info: any) {
    this.selectedInfo = info;
  }

  ngOnDestroy() {
    console.log("Destroy")
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getStroiesForCar(vehicleVin: any, page: any){
    this.myStories.getStroiesForVin(vehicleVin, this.ciamId, page, 10).subscribe((data: any) => {
      this.pageNumber.emit(data.total_pages);
      data.stories.forEach((story: any) => {
        let date = new Date(story.createdAt * 1000);
        let dateYear = date.getFullYear()
        // if the map has year already then it will just append the story value to the current map
        if (this.map.has(dateYear)) {
          const storiesList = this.map.get(dateYear);
          storiesList.push(story);
          this.map.set(dateYear, storiesList);

        }
        // else it will add year and value
        else {
          this.map.set(dateYear, [story]);

        }
        // reversing the map
        let storyMap = new Map([...this.map].reverse());
        this.myStory = storyMap;
      });
      if (this.myStory === undefined) this.myStoryCount = true;
      this.isLoading = false;
    });
  }

  public scrollTop(): void {
    // this.scrollToTop.emit('#target');
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    })
  }

}
