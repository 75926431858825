import { ImageAssetsInfo } from './../../../../components/interfaces/race-share.interface';
import { Component, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-event-impressions',
  templateUrl: './event-impressions.component.html',
  styleUrls: ['./event-impressions.component.scss'],
})
export class EventImpressionsComponent {
  translationData: any;
  isDownloadButtonVisible: boolean = false;
  featureFlags: any;

  constructor(private translationService: TranslationService){
    this.featureFlags = this.translationService.featureFlags;
    this.isDownloadButtonVisible = this.featureFlags.general.enableDownloadAllButton;
  }

  @Input() images!: ImageAssetsInfo[];
  ngOnInit(){
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }


  downloadImages(imageUrl: string, name: string) {
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = imageUrl;
    document.body.appendChild(anchor); // Append to the body
    anchor.click();
    document.body.removeChild(anchor); // Remove from the body
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public async downloadMedia(): Promise<void> {
    for (let i = 0; i < this.images.length; i++) {
      const image = this.images[i];
      if (image.url) {
        const fileName = this.generateTimestampedFileName(image.url);
        this.downloadImages(image.url, fileName);
        await this.delay(1000); // 1 second delay
      }
    }
  }

  generateTimestampedFileName(url: string): string {
    const timestamp = new Date().toISOString().replace(/[:.-]/g, '');
    const extension = url.split('.').pop();
    return `image_${timestamp}.${extension}`;
  }

  isMediaSensitive(isMediaSensitiveKey: boolean | undefined): boolean {

    return !!isMediaSensitiveKey;

  }



  setMediaSensitive(event: boolean, index: number): void {

    if (this.images[index]) {

      this.images[index].isSensitive = event;

    }
  }


}
