<!-- Moment card -->
<div *ngIf="story.itemType === 'MOMENT'">
  <div class="card-flex-box" [ngClass]="story.itemType === 'MOMENT' ? 'moment-container' : ''">
    <div class="img-reg">
      <div class="media-container">
        <div *ngFor="let media of story.media;let i = index" class="media-item">
          <ng-container>
            <app-content-filtering *ngIf="isMediaSensitive(media.isSensitive)" [sensitiveImage]="media.url"
              (setMediaSensitive)="setMediaSensitive($event, i)">

            </app-content-filtering>
            <div class="img-fluids"
              [ngStyle]="{'background-image': 'url(' + media.url + '), url(' + media.thumbnail_640 + ')'}">
            </div>
            <!-- <img class="img-fluid" [src]="media.url" alt="" /> -->
          </ng-container>
        </div>
      </div>
    </div>
    <div class="info-panel-bottom-reg">
      <h2 class="moment-title">{{ story.description[0].value }}</h2>
      <div class="rowele">
        <app-tags-component [tagClass]="'tag'" [tagTitle]="date | date : 'dd.MM.yyyy'"></app-tags-component>
      </div>
    </div>
    <!-- <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
      <span class="slideerNumber">{{ currentIndex + 1 }}/{{ story.media.length }}</span>
    </div> -->
    <div *ngIf="story.media.length > 1">
      <button (click)="scroll(-1)" class="arrow-button left" *ngIf="this.currentIndex != 0"></button>
      <button (click)="scroll(1)" class="arrow-button right"
        *ngIf="this.currentIndex != story.media.length - 1"></button>
    </div>
  </div>
</div>


<!-- Track Race -->
<div *ngIf="story.itemType === 'TRACKRACE'" (click)="naviagateToExperience()" class="track-card">
  <div class="card-flex-box" [ngClass]="story.itemType === 'TRACKRACE' ? 'trackRace-container' : ''">
    <div class="media-container">
      <div class="media-item" *ngFor="let media of story.media; let i = index">
        <ng-container>
          <div class="vd-pd-msg tour-card-main" *ngIf="media.status !='SUCCESS'">
            <span class="pending-message">{{'CP_PENDING_VIDEO_MSG' | customTranslation: translationData}}</span>
          </div>
          <app-content-filtering *ngIf="isMediaSensitive(media.isSensitive)" [sensitiveImage]="media.url"
            (setMediaSensitive)="setMediaSensitive($event, i)">

          </app-content-filtering>
          <div *ngIf="media.mediaType =='image' && media.status == 'SUCCESS'" class="img-fluids"
            [ngStyle]="{'background-image': 'url(' + media.url + '), url(' + media.thumbnail_640+ ')'}">
          </div>
          <!-- <img class="img-fluid" *ngIf="media.mediaType == 'image' && media.status =='SUCCESS'" [src]="media.url" alt="" />  -->
          <video *ngIf="media.mediaType == 'VIDEO' && media.status =='SUCCESS'" class="img-fluid" [muted]="!volume" [autoplay]="false" preload="none"
                 [loop]="true" [poster]="media.thumbnail_640">
            <source [src]="media.url" type="video/mp4" />
          </video>
        </ng-container>
      </div>
    </div>

    <div [ngClass]="isPublished ? 'info-panel-bottom-publish' : 'info-panel-bottom'">
      <!-- Event Name -->
      <p class="event-title">{{ story.raceData.raceName }}</p>
      <div class="rowele">
        <!-- lap Count, Event sunType: Exercise name -->
        <app-tags-component [tagClass]="'tag-red tag'"
          *ngIf="story.raceData.exerciseType ==='BP_EM_EXERCISE_BESTTIME' && story.subType ==='EVENT'"
          [tagTitle]="story.raceData.exerciseName"></app-tags-component>
        <app-tags-component [tagClass]="'tag-red tag'" *ngIf="story.subType ==='PERSONAL'"
          [tagTitle]="story.raceData.lapCount ===1 ? story.raceData.lapCount +' lap': story.raceData.lapCount+' laps'"></app-tags-component>
        <app-tags-component [tagClass]="'tag-red tag'"
          *ngIf="story.raceData.exerciseType ==='BP_EM_EXERCISE_DRIFT' && story.subType ==='EVENT'"
          [tagTitle]="story.raceData.exerciseName"></app-tags-component>

        <!-- Race start time -->
        <ng-container *ngIf="story.raceData.exerciseType ==='BP_EM_EXERCISE_DRIFT'; else timeTagComponent">
          <app-tags-component [tagClass]="'tag'" *ngIf="story.raceData.driftScore !== null && story.raceData.driftScore !== undefined"
            [tagTitle]="(getDriftScore()) + ' '+story.raceData.driftScoreUnit"></app-tags-component>
        </ng-container>

        <ng-template #timeTagComponent>
          <app-tags-component [tagClass]="'tag'" [tagIconClass]="'tagIcon'"
            *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration >= 60 && story.raceData.bestLapDuration < 600000"
            [tagTitle]="(story.raceData.bestLapDuration * 1000 | date: 'm:ss.SS': 'UTC') + ' min'"></app-tags-component>
          <app-tags-component [tagClass]="'tag'" [tagIconClass]="'tagIcon'"
            *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration >= 600000"
            [tagTitle]="(story.raceData.bestLapDuration * 1000 | date: 'mm:ss.SS': 'UTC') + ' min'"></app-tags-component>
          <app-tags-component [tagClass]="'tag'" [tagIconClass]="'tagIcon'"
            *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration < 60"
            [tagTitle]="(story.raceData.bestLapDuration * 1000 | date: '0:ss.SS': 'UTC') + ' min'"></app-tags-component>
        </ng-template>


        <!-- time and date -->
        <app-tags-component *ngIf="!!date" [tagClass]="'tag'"
          [tagTitle]="date | date : 'dd.MM.yyyy'"></app-tags-component>
      </div>
      <div class="publish-experience" *ngIf="isPublished">Publish Experience</div>
    </div>
    <!-- <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
      <span class="slideerNumber">{{ currentIndex + 1 }}/{{ story.media.length }}</span>
    </div> -->
  </div>
</div>

<!-- Offroad Tour -->
<div *ngIf="story.itemType === 'OFFROAD'" (click)="naviagateToExperience()" class="track-card">
  <div class="card-flex-box">
    <div class="image">
      <div class="media-container">
        <div class="media-item" *ngFor="let media of story.media; let i = index">
          <ng-container>
            <div class="vd-pd-msg  tour-card-main-offroad" *ngIf="media.status !='SUCCESS'">
              <span class="pending-message">{{'CP_PENDING_VIDEO_MSG' | customTranslation: translationData}}</span>
            </div>
            <app-content-filtering *ngIf="isMediaSensitive(media.isSensitive)" [sensitiveImage]="media.url"
              (setMediaSensitive)="setMediaSensitive($event, i)">

            </app-content-filtering>
            <div *ngIf="media.mediaType =='image' && media.status == 'SUCCESS'" class="img-fluids"
              [ngStyle]="{'background-image': 'url(' + media.url + '), url(' + media.thumbnail_640 + ')'}">
            </div>
            <!-- <img class="img-fluid" *ngIf="media.mediaType == 'image' && media.status =='SUCCESS'" [src]="media.url" alt="" /> -->
            <video *ngIf="media.mediaType == 'VIDEO' && media.status == 'SUCCESS'" class="img-fluid" [muted]="!volume" [autoplay]="false" preload="none" [loop]="true" [poster]="media.thumbnail_640">
               <source [src]="media.url" type="video/mp4" />
</video>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="info-panel-bottom">
      <!-- Em Title -->
      <p class="event-title">{{ story.raceData.raceName }}</p>
      <div class="rowele">
        <!-- Emotion Tour  -->
        <app-tags-component [tagClass]="'tag-amber tag'"
          [tagTitle]="offroadTitle |  customTranslation: translationData"></app-tags-component>
        <!-- Total distance -->
        <app-tags-component *ngIf="!!story.distance" [tagClass]="'tag'"
          [tagTitle]="story.distance"></app-tags-component>
        <!--  start time -->
        <app-tags-component [tagClass]="'tag'" [tagIconClass]="'tagIcon'"
          *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration>=60 && story.raceData.bestLapDuration<600000"
          [tagTitle]="(story.raceData.bestLapDuration *1000 | date:'m:ss.SS': 'UTC')+ ' min'"></app-tags-component>
        <app-tags-component [tagClass]="'tag'" [tagIconClass]="'tagIcon'"
          *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration>=600000"
          [tagTitle]="(story.raceData.bestLapDuration *1000 | date:'mm:ss.SS': 'UTC')+ ' min'"></app-tags-component>
        <app-tags-component *ngIf="!!story.raceData.bestLapDuration && story.raceData.bestLapDuration<60"
          [tagClass]="'tag'"
          [tagTitle]="(story.raceData.bestLapDuration*1000 | date:'0:ss.SS':'UTC') +' min'"></app-tags-component>
        <!-- time and date -->
        <app-tags-component *ngIf="!!raceStartTime" [tagClass]="'tag'"
          [tagTitle]="raceStartTime | date : 'dd.MM.yyyy · HH:mm'"></app-tags-component>
      </div>
    </div>
    <!-- <div class="tag-item slideIndex" *ngIf="story.media.length > 1">
            <span class="slideerNumber">{{currentIndex+1}}/{{story.media.length}}</span>
        </div> -->
  </div>
</div>

<!-- Event in progress card -->
<div *ngIf="story.itemType === 'EventInProgress'">
  <div class="card-event">
    <div class="image-reg">
      <div class="media-container">
        <div class="media-item black-overlay">
          <img class="img-fluid" src="../../../../../../assets/images/museum.jpg" />
        </div>
      </div>
      <p class="info-txt-box">The event data are currently being processed. We will notify you via e-mail when your
        Experience in ready.</p>
      <span class="link-liveLeaderBoard">View Leaderboard</span>
    </div>


    <div class="info-panel-bottom">
      <!-- Event Name -->
      <span class="event-title"> Driving Academy Bilster Berg 2024</span>
      <div class="rowele">
        <!-- lap Count -->
        <app-tags-component [tagClass]="'tag-red tag'" [tagTitle]="'Best time challenge'"></app-tags-component>
        <!-- Race start time -->
        <app-tags-component [tagClass]="'tag'" [tagTitle]="'1:44.27 min'"></app-tags-component>

        <!-- time and date -->
        <app-tags-component [tagClass]="'tag'" [tagTitle]="'03.06.2024'"></app-tags-component>
      </div>
    </div>
  </div>
</div>
