
<!-- edit profile component -->
<ng-template #editPopup >
    <div *ngIf="getProfileResponse; else showSpinner">
    <div class="lt-row-w-g">
        <div class="clm-6 clm-tab-8">
            <div class="profile-sec">
                <div class="usr-img">
                    <div *ngIf="profileImage">
                        <img style="background-image: url({{profileImage}});" alt="" />
                    </div>
                    <div *ngIf="!profileImage">
                        <span class="initals">{{profileInitials}}</span>
                    </div>
                </div>
                <div class="usr-info">
                    <h3>{{profileName}}</h3>
                    <span *ngIf="profileEmail!=null">{{profileEmail}}</span>
                </div>
            </div>
        </div>
        <div class="clm-6 clm-tab-8">
            <div class="account-sec">
                <span>{{'CP_SM_EDITPROFILE_SUBMESSAGE' | customTranslation: translationData}}</span>
            </div>

        </div>
            <div class="line"></div>
            <span class="text-color">{{'CP_SM_EDITPROFILE_MESSAGE' | customTranslation: translationData}}
            </span>
        <div class="lt-row-w-g">
            <form [formGroup]="editProfileForm" >
                <div class="prof-info-box">
                    <div class="clm-6">

                        <div class="form-row outline ot1x">
                            <div class="form-group">
                                <div class="form-item material stable full-w ">
                                    <input class="outline-inbox"  maxlength="64" formControlName="nickName" type="text" autocomplete="off" />
                                    <label class="label"> {{'CP_SM_EDITPROFILE_NICKNAME' | customTranslation: translationData}}</label>
                                    <div class="t-a-bd-bt"></div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row outline ot1x">
                            <div class="form-group">
                                <div class="form-item material stable full-w">
                                    <input class="outline-inbox" maxlength="300" formControlName="socialLink" type="text" autocomplete="off" placeholder="https://"/>
                                    <label class="label">{{'CP_SM_EDITPROFILE_SOCIALLINK' | customTranslation: translationData }}</label>
                                    <div class="t-a-bd-bt"></div>
                                </div>
                            </div>
                            <div class="form-note-text text-color">
                                <span>{{'CP_SM_EDITPROFILE_URLMESSAGE'| customTranslation: translationData}}</span>
                            </div>
                        </div>



                    </div>
                    <div class="clm-6 ">

                        <div class="form-row outline ot2x">
                            <div class="form-group">
                                <div class="form-item material stable full-w h-100">
                                    <textarea maxlength="300" type="text" autocomplete="off" rows="1" placeholder=""
                                        formControlName="description" name="profileDesc"></textarea>
                                    <label class="label">{{'CP_SM_EDITPROFILE_DESCRIPTION' | customTranslation: translationData}}</label>
                                    <div class="t-a-bd-bt"></div>

                                </div>
                            </div>
                            <div class="form-note-text left">
                                <span class="word-limit">
                                    {{ editProfileForm.get('description')?.value?.length || 0 }}/300 {{'CP_SM_EDITPROFILE_CHARACTERLIMIT' | customTranslation: translationData}}
                                  </span>
                            </div>
                        </div>


                    </div>
                </div>
            </form>
        </div>


    </div>

    </div>
</ng-template>

<ng-template #showSpinner>
    <app-spinner-component></app-spinner-component>
</ng-template>

<app-common-modal [isCloseModal]="false" [closeButtonTitle]="'CP_SM_CANCEL' | customTranslation: translationData"
[saveButtonTitle]="'CP_SM_EDITPROFILE_UPDATE' | customTranslation: translationData"
    [isHeaderVisible]="true" [isFooterVisible]="true" component="create-edit-alias" [showModalContent]="editPopup"
    (saveModalEmitter)="saveProfileData()" (closeModalEmitter)="closeModal()" *ngIf="showEditPopup"
    [type]="popupType">
</app-common-modal>
