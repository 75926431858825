import { Component, Input } from '@angular/core';
import { VideoAssetsInfo } from 'src/app/components/interfaces/race-share.interface';

@Component({
  selector: 'app-highlight-video',
  templateUrl: './highlight-video.component.html',
  styleUrls: ['./highlight-video.component.scss']
})
export class HighlightVideoComponent {
@Input() videoAssetsInfo!: VideoAssetsInfo[] ;
emotionalVideoIndex: number = -1;
constructor(){

}
ngOnInit(): void {
  this.getEmotionalVideoOnLoad();
}

getEmotionalVideoOnLoad() {
  this.emotionalVideoIndex = this.videoAssetsInfo.findIndex((video: VideoAssetsInfo) => video.type === 'EMOTIONAL_VIDEO');
}
}
