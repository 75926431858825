import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-content-filtering',
  templateUrl: './content-filtering.component.html',
  styleUrls: ['./content-filtering.component.scss']
})
export class ContentFilteringComponent {
@Input() sensitiveImage!: string;
@Output() setMediaSensitive = new EventEmitter<boolean>();
@Input() isImpressionAvailable: boolean = false;
translationData: any;

  constructor(private _translationService: TranslationService) {
    this._translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

onClick(event: Event): void {
  this.setMediaSensitive.emit(false);
  this.stopPropagation(event);
}

stopPropagation(event: Event): void {
  event.stopPropagation();
}


}
