import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  participationinfo: string = '';
  eventId: string = '';
  partcipationDetails = {
    participantId: '',
    participantKey: '',
  };

  translationData = [];
  language: string = '';
  selectedLang: any;
  constructor(
    private route: ActivatedRoute,
    private awsAuth: AwsAuthService,
    private router: Router,
    private translationService: TranslationService,
    private languageService: LanguageService,
  ) {  
    let selectedLanguage = localStorage.getItem('language') ?? 'en-GB';
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
  }

  ngOnInit(): void {
    this.language = this.languageService.getLanguage();
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    const participantDetailsFromUrl = this.route.snapshot.paramMap.get('partipationInfo');
    const eventId = this.route.snapshot.paramMap.get('eventId');
    if(eventId){
      localStorage.setItem('routeUrl','/register' + eventId);
      this.route?.params?.subscribe((param) => {
        this.eventId = param['eventId'];
        sessionStorage.setItem('eventId',eventId)
      })
    }else{
      localStorage.setItem('routeUrl', '' + participantDetailsFromUrl);
      this.route?.params?.subscribe((param) => {
        this.participationinfo = atob(param['partipationInfo']);
        let participantInfo = this.participationinfo.split('/');
        this.partcipationDetails.participantId = participantInfo[0];
        this.partcipationDetails.participantKey = participantInfo[1];
      });
      sessionStorage.setItem(
        'partcipationDetails',
        JSON.stringify(this.partcipationDetails)
      );
    }
      let userLoggedIn = this.awsAuth.getUserLoggedInStatus()
  
      if(this.awsAuth.getUserLoginStatus()) {      
        this.awsAuth.currentSession().then(
          (res: any) => {
            let accessToken = res.getAccessToken()
            let jwt = accessToken.getJwtToken();
            let jwt_expires_in = accessToken.getExpiration();
            let refreshToken = res.getRefreshToken();
            let jwtRefreshToken = refreshToken.getToken();
            let idToken = res.getIdToken(); 
            let jwtIdToken = idToken.getJwtToken();
            let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
            this.awsAuth.setUserLoginStatus(true);
            this.awsAuth.setCurrentUserToken(fullData);
            if (!userLoggedIn) {
              localStorage.removeItem('currentCIAMUser');
              localStorage.removeItem('token');
              this.awsAuth.signIn();
            } else if (this.partcipationDetails) {
                this.router.navigateByUrl('/'+ this.language+'/stories/link-participant');
                localStorage.removeItem('routeUrl');
              } else if (this.eventId) {
                this.router.navigateByUrl('/'+ this.language+'/stories/register-user');
                localStorage.removeItem('routeUrl');
              }
          },
          (error: any) => {
            sessionStorage.setItem('refreshTokenExpired','true');
            this.awsAuth.logout();  
          })
      } else {
        sessionStorage.setItem('refreshTokenExpired','true');
        this.awsAuth.signIn();
      }    
    
    }
    
}
