import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyStoriesService } from 'src/app/modules/stories/services/my-stories.service';
import { AppServicesService } from 'src/app/services/app-services.service';
import { CommonModalServiceService } from 'src/app/services/common-modal-service.service';
import { MyProfileService } from 'src/app/services/my-profile-service.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
import { TranslationService } from 'src/app/services/translation.service';
import { ProfileDetails } from '../interfaces/myStories.interface';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  constructor(
    private commonModalService: CommonModalServiceService,
    private fb: FormBuilder,
    public _appService: AppServicesService,
    public toastService: ToastService,
    public translationService: TranslationService,
    private myStoriesService: MyStoriesService,
    private myProfileService: MyProfileService
  ) { }

  public editProfileForm !: FormGroup;
  @ViewChild('editPopup') editPopup: TemplateRef<any> | undefined;
  @Output() public cancelClicked = new EventEmitter<any>();
  showEditPopup: boolean = true;
  isHeaderVisible: boolean = true;
  isCloseModal: boolean = false;
  showModalContent: boolean = true;
  profileName: string = '';
  popupType: string = 'large-popup'
  profileEmail: string = '';
  defaultBanner = "../../../assets/mercedes.png";
  profileImage = "";
  profileInitials: string = '';
  ciamId = '';
  isShowToast = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;
  translationData = [];
  getProfileResponse: boolean = false;
  @Input() isStoriesSection: boolean = false;
  @Input() userId: any;
  isErrorOccured: boolean =false;

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(true);
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(true);
    this.commonModalService._modalHeaderTitle.next('CP_LM_MAINPAGE_MYPROFILE');
    this.editProfileForm = this.fb.group({
      'nickName': [''],
      'socialLink': [''],
      'description': [''],
    });
    if (!this.isStoriesSection) {
      if(this.myProfileService.profileInfoResponse){
        let data = this.myProfileService.profileInfoResponse;
        if(data){
          this.getProfileInfo(data);
        }
      }
    }
    else {
      this.myStoriesService.getProfile(this.userId).subscribe(
        (data) => {
          this.getProfileInfo(data);
        }
      )
    }
  }

  public prePoulatedProfileForm(data: any): void {
    if(this.editProfileForm){
      this.editProfileForm.patchValue({
        nickName: data.nickName,
        socialLink: data.socialLink,
        description: data.description
      })
    }
  }

  public saveProfileData(): void {

    this.editProfileForm.patchValue({
      nickName: this.editProfileForm.value.nickName?.trim(),
      socialLink: this.editProfileForm.value.socialLink?.trim(),
      description: this.editProfileForm.value.description?.trim()

    });


    if (this.editProfileForm.valid) {
      const reqObj = this.editProfileForm.value;
      reqObj.nickName = this.editProfileForm.value.nickName;
      reqObj.socialLink = this.editProfileForm.value.socialLink;
      reqObj.description = this.editProfileForm.value.description;
      this.myStoriesService.updateProfile(reqObj.nickName, reqObj.description, reqObj.socialLink, this.ciamId).subscribe(
        () => {
          this.myProfileService.profileInfoResponse.nickName = reqObj.nickName;
          this.myProfileService.profileInfoResponse.socialLink = reqObj.socialLink;
          this.myProfileService.profileInfoResponse.description = reqObj.description;
          this.showEditPopup = false;
          this.cancelClicked.emit(true);
          this.toastService.show(ToastType.Success, 'CP_SM_TOAST_MSG_SUCCESS', '8%', '40%');
          this.myStoriesService.setIsProfileUpdate(true);
          this.myStoriesService.hideSpinner();
          setTimeout(() => {
            this.toastService.hide();
          }, 4000);
        },
        (error: HttpErrorResponse) => {
          this.updateErrorProfileResponse(error);
        }
      )
    }
  }


  closeModal() {
    this.showEditPopup = false;
    this.cancelClicked.emit(true);
  }

  getProfileInfo(data: ProfileDetails){
    this.getProfileResponse = true;
    this.profileName = data.firstName + " " + data.lastName;
    this.profileImage = data.profileImageUrl;
    this.profileEmail = data.email;
    this.profileInitials = data.firstName.charAt(0).toUpperCase() + data.lastName.charAt(0).toUpperCase();
    this.ciamId = data.userId;
    this.prePoulatedProfileForm(data);
  }

  updateErrorProfileResponse(error: HttpErrorResponse){
    this.isErrorOccured = true;
    this.myStoriesService.hideSpinner();
    if (error.error['error-code'] === "8005")
      this.toastService.show(ToastType.Error, 'CP_SM_TOAST_MSG_SOCLNK_INVALID', '8%', '40%');
    setTimeout(() => {
      this.toastService.hide();
    }, 4000);
    if (error.error['error-code'] === "9009")
      this.toastService.show(ToastType.Error, 'CP_SM_TOAST_MSG_SOCLNK_ERR', '8%', '40%');
    setTimeout(() => {
      this.toastService.hide();
    }, 4000);
    if (error.error['error-code'] === "9008")
      this.toastService.show(ToastType.Error, 'CP_SM_TOAST_MSG_DESC_ERR', '8%', '40%');
    setTimeout(() => {
      this.toastService.hide();
    }, 4000);
    if (error.error['error-code'] ==="9007")
      this.toastService.show(ToastType.Error, 'CP_SM_TOAST_MSG_NICKNAME_ERR', '8%', '40%');
    setTimeout(() => {
      this.toastService.hide();
    }, 4000);
  }

}
