import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserInfo } from 'src/app/components/interfaces/myStories.interface';
import { TranslationService } from 'src/app/services/translation.service';
import { MyStoriesService } from '../../services/my-stories.service';
import { MyProfileService } from 'src/app/services/my-profile-service.service';



@Component({
  selector: 'app-profile-component',
  templateUrl: './profile-component.component.html',
  styleUrls: ['./profile-component.component.scss']
})
export class ProfileComponentComponent implements OnInit {
@Input()  profileDetails !: UserInfo;
profileImage = "../../../../../assets/images/profile-fallback.png";
@Output() isProfileClicked = new EventEmitter<boolean>();
@Input() shareProfileUrl: any;
@Input() userId: any;
featureFlags: any;

isShareProfileVisible : boolean = false;
  translationData: any;
  displayName: string = '';
  constructor(private translationService: TranslationService, 
    private myStoriesService: MyStoriesService,
    private _profileService: MyProfileService
  ) {
    this.featureFlags = this.translationService.featureFlags;
    this.myStoriesService.getIsProfileUpdated().subscribe((isUpdated: boolean) => {
      if (isUpdated) {
        this.displayName = this.profileDetails.nickName = this._profileService.profileInfoResponse.nickName;
        this.profileDetails.socialLink = this._profileService.profileInfoResponse.socialLink;
        this.profileDetails.description = this._profileService.profileInfoResponse.description
        if (!this.displayName) {
          this.displayName =  this.profileDetails.firstName + " " + this.profileDetails.lastName
        }
      }
    })
   }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    if(this.featureFlags.general.enableShareProfileButton){
      this.isShareProfileVisible = true
    }
  }

  ngOnChanges(): void {
    this.displayName = this.profileDetails.nickName || this.profileDetails.firstName + " " +  this.profileDetails.lastName
  }



  profileClicked() {
    this.myStoriesService.editProfilePopupVisible(true); // Show popup
  }


  socialLinkClicked(){
    window.open(this.profileDetails.socialLink, '_blank');
  }

  shareProfileClicked(){

  }

}
