
<div class="main-region">
  <ng-container>
    <div class="confirm-button-app">
      <div class="alert-icon-reg">
          <div class="alert-icon ">
              <span  *ngIf="failure" class="icon icon_failed"></span>
              <span  *ngIf="success"class="icon icon_success"></span>
              <span  *ngIf="info"class="icon icon_info"></span>
              <span  *ngIf="infoMessageAlert"class="icon icon_success icon-info-msg"></span>
          </div>
      </div>
      <div>
            <h2 class="info-msg">{{responseMessage | customTranslation: translationData}}</h2>
            <span class="subInfo-msg">{{responseInfoMessage | customTranslation: translationData}}</span>
    </div>
    <ng-container *ngIf="showLogoutOption">
      <div class="lt-fr-row">
        <div class="log-out-link" (click)="logout()">
            <span>{{'CP_LM_LANDINGPAGE_LOGOUT' | customTranslation: translationData
                }}</span>
        </div>
    </div>
    </ng-container>
  </div> 
</ng-container>
</div>

