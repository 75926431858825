<div class="vehicle-page-main" *ngIf="!showErrorPage && carsDetails?.garage?.length!=0 && isShowSpinner && !isPageNotFound" (scroll)="onScroll($event)">

    <div class="vehicle-page-container" >
        <div id="v-page" class="main-sec">
            <div class="profile-about-social">
                
                <div id="prof-large">
                    <app-profile-component [profileDetails]="profileDetails" [shareProfileUrl]="shareProfileUrl" (isProfileClicked)="isProfileClicked" (isStoriesSection)="isStoriesSection"></app-profile-component>  
                </div>
                <div id="prof-mobile" *ngIf="profileDetails">
                    <app-profile-mobile-view [profileDetails]="profileDetails" [shareProfileUrl]="shareProfileUrl" [userId]="ciamId"></app-profile-mobile-view>
                </div>
            </div>
  
            

            
            <div class="car-cards-section-garage" *ngIf="carsDetails?.garage?.length!=0">
                <div class="user-car-garage"  *ngIf="carsDetails">
                    <app-car-garage [garageDetails]="carsDetails" [vinOrFinFromRoutes]="vinOrFinFromRoute" [ciamId]="ciamId" (pageNumber)="totalNoOfPages($event)" (vehicleVinOfCar)="vehicleVin($event)" [isMinCardActive]="isMinCardActive"></app-car-garage>
                </div>
            </div>
        </div>
        

    </div>
   
</div>

<div *ngIf="carsDetails?.garage?.length==0" style="height: 100%;">
    <app-no-vehicles [profileDetails]="profileDetails"></app-no-vehicles>
</div>

<div *ngIf="!isShowSpinner" >
    <app-spinner-component></app-spinner-component>
</div>

<div *ngIf="popupVisible" >
    <app-edit-profile (cancelClicked)="cancelClicked()" [isStoriesSection]="isStoriesSection" [userId]="ciamId"></app-edit-profile>
</div>


<ng-container *ngIf="isPageNotFound">
    <app-no-route ></app-no-route>
</ng-container>
<app-response-page *ngIf="showErrorPage" [responseMessage]="'CP_SM_USER_NOT_FOUND'" [failure]="true"></app-response-page>

