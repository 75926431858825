import {
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { DataService } from '../../services/data-services/data-service';
import { ImageAssetsInfo, RaceShareResponse } from 'src/app/components/interfaces/race-share.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationService } from 'src/app/services/translation.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
@Component({
  selector: 'app-race-share-main',
  templateUrl: './race-share-main.component.html',
  styleUrls: ['./race-share-main.component.scss'],
})
export class RaceShareMainComponent implements OnInit {
  isShowSpinner: boolean = false;
  isBackButtonVisible: boolean = true;
  raceSharePageInfo!: RaceShareResponse;
  isLeaderBoardVisible: boolean = false;
  isImpressionsVisible: boolean = true;
  id: string = '';
  selectedLanguage = 'en-GB';
  translationData = [];
  totalPages = 0;
  public page: any = 0;
  isAccessDenied: boolean = false;
  accessTokenData = { access_token: '' };
  isNotFound = false;
  isLoggedIn = false;
  isBestTimeChallenge: boolean = false;
  isDriftChallenge: boolean = false;
  isOffroad:boolean = false;
  isTrackError: boolean = false;
  trackGuid!: string;
  trackInfo: any;
  isSubType!: any;
  exercisename!:string;
  vinOrFin!: string;
  subType!: string;
  isPublished: boolean = false;
  isHidden: boolean = false;
  imageAssetsInfo!: ImageAssetsInfo[];
  itemType!: string;
  subTypes = ['PERSONAL','EVENT']
  showEventDetails: boolean = false;
  showHighlightVideo: boolean = false;
  showTelemetricCard: boolean = false;
  showEventTrack: boolean = false;
  showCarDetails: boolean = false;
  showRaceVideos: boolean = false;
  showImpressions: boolean = false;
  showPublishButton: boolean = false;
  enablePublishButton: boolean = false;
  featureFlags: any;
  showBackBtnForLoggedInUser: boolean = true;


  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private awsAuth: AwsAuthService,
    private translationService: TranslationService,
    public toastService: ToastService,
  ) {
    this.featureFlags = this.translationService.featureFlags;
    this.dataService.hide$.subscribe(hide => this.isHidden = hide);
    this.enablePublishButton = this.featureFlags.general.enablePublishExp;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosition = document.getElementById('race-share-container');
    let positionTop:any = scrollPosition?.getBoundingClientRect()['top']
    if ( positionTop < 60) {
      this.isBackButtonVisible = false;
    } else {
      this.isBackButtonVisible = true;
    }
  }


  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.route?.params.subscribe((params: any): void => {
      this.id = params.id;
    });
    this.selectedLanguage = localStorage.getItem('language') || 'en-GB';
    let userLoggedIn = this.awsAuth.getUserLoggedInStatus();
    if (!userLoggedIn) {
      this.selectedLanguage = localStorage.getItem('language') || 'en-GB';
      localStorage.setItem('routeUrl', this.router.url);
      localStorage.removeItem('currentCIAMUser')
      localStorage.removeItem('token')
      this.showBackBtnForLoggedInUser = false;
      // this.getRaceShareAccessToken();
      this.getRaceDetails(this.id);
    } else {
      this.showBackBtnForLoggedInUser = true
      this.getRaceDetails(this.id);
    }

  }


  // getCiamId(): void{
  //   this.myProfileService.getProfile().subscribe()
  // }

// Ayushi to check how to make this code reusable
// Will mostly be handled by proxy server
  getRaceShareAccessToken() {
    this.awsAuth.currentSession()?.then(
      (_res) => {
        let accessToken = _res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        let jwt_expires_in = accessToken.getExpiration();
        let refreshToken = _res.getRefreshToken();
        let jwtRefreshToken = refreshToken.getToken();
        let idToken = _res.getIdToken();
        let jwtIdToken = idToken.getJwtToken();
        let fullData = {
          id_token: jwtIdToken,
          access_token: jwt,
          refresh_token: jwtRefreshToken,
          expires_in: jwt_expires_in,
          token_type: 'Bearer',
        };
        this.awsAuth.setUserLoginStatus(true);
        this.awsAuth.setCurrentUserToken(fullData);
        this.accessTokenData = this.awsAuth.getCurrentUserToken();
        this.getRaceDetails(this.id);
      },
      (err: HttpErrorResponse) => {
        //
        this.awsAuth.signIn();
      }
    );
  }

  getRaceDetails(id: string): void {
    const isUserLoggedIn: boolean = this.awsAuth.getUserLoggedInStatus();
    this.dataService.getRaceShareInfo(id, isUserLoggedIn).subscribe(
      (data: any) => {
        this.raceSharePageInfo = data;
        this.isPublished = this.raceSharePageInfo.raceInfo.isPublished;
        this.isSubType = data.subType;
        this.itemType = data?.itemType;
        this.trackGuid = this.raceSharePageInfo.trackInfo.trackId;
        this.eventTrackDetailsData(this.trackGuid, isUserLoggedIn);
        this.imageAssetsInfo = this.raceSharePageInfo.imageAssetsInfo;
        this.isBestTimeChallenge = (data.eventInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME') ||
          this.raceSharePageInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME') || (data.itemType === 'TRACKRACE' && data.subType === 'PERSONAL'))
          ?? false;
        this.isDriftChallenge = (this.raceSharePageInfo?.eventInfo?.exerciseInfo?.exerciseType?.includes('DRIFT')  || this.raceSharePageInfo?.exerciseInfo?.exerciseType?.includes('DRIFT')) ?? false;
        this.vinOrFin = this.raceSharePageInfo.vehicleInfo.vin;
        this.subType = this.raceSharePageInfo.subType;
        this.isOffroad = this.raceSharePageInfo?.itemType === 'OFFROAD' ? true: false
        this.getSectionVisibility();
      },
      (error: HttpErrorResponse) => {
        this.isShowSpinner = true;
        if (error.error['error-code'] === '8010') {
          // 403 error code : Provided ciamId is either invalid or doesn't have enough access!
          // If error code is 403 and user is not logged in, make them signout
          // In our case, race details page is not yet public
          if (!isUserLoggedIn) {
            localStorage.setItem('routeUrl', this.router.url);
            localStorage.removeItem('currentCIAMUser')
            localStorage.removeItem('token')
            this.awsAuth.signIn();
          } else {
            this.isAccessDenied = true;
          }
        } else if (error.error['error-code'] === '9006') {
          // redirect to 404 : url is invalid
          this.router.navigateByUrl('/' + this.selectedLanguage + '/404');
        } else {
          this.router.navigateByUrl('/' + this.selectedLanguage + '/404');
          console.log('Error while fetching participant info');
        }
      }
    );
  }

  eventTrackDetailsData(trackGuid: string, isUserLoggedIn: boolean) {
    this.dataService.getTrack(trackGuid, isUserLoggedIn).subscribe((data: any) => {
      this.trackInfo = data;
      this.isShowSpinner = true;
    },
      (error: HttpErrorResponse) => {
        this.isTrackError = true;
        this.isShowSpinner = true;
      });
  }

  publishExp() : void{
    this.dataService.publishExperience(this.id, this.vinOrFin, this.subType).subscribe(
      (data)=>{
      this.toastService.show(ToastType.Success, 'RSP_SUCCESS_PUBLISH_EXP', '8%', '40%');
            setTimeout(() => {
              this.toastService.hide();
            }, 6000);
            this.dataService.hideElements();
            this.router.navigateByUrl(`${this.selectedLanguage}/stories`);
      },
      (error: HttpErrorResponse) => {
        this.toastService.show(ToastType.Error, 'RSP_ERR_PUBLISH_EXP', '8%', '40%');
            setTimeout(() => {
              this.toastService.hide();
            }, 6000);
      })
  }
  getSectionVisibility(){
    // Assign section wise visibility conditions
    if(this.subTypes.includes(this.raceSharePageInfo.subType)){
      if(this.enablePublishButton)   this.showPublishButton = this.subType==='EVENT' && !this.isPublished;
      this.showEventDetails = true;
      this.showHighlightVideo = true;
      this.showTelemetricCard = true;
      this.showEventTrack = true;
      this.showCarDetails = true;
      this.showRaceVideos = this.isOffroad ? false : true; // race videos not shown in offroad, as per miro
      this.showImpressions = true;
    }
  }


  public navigateToMyStories(): void{
    this.router.navigateByUrl(`${this.selectedLanguage}/stories`);
  }
}
