import { Component, Input, OnInit } from '@angular/core';
import { ParticipantInfo, RaceShareResponse, RaceInfo } from 'src/app/components/interfaces/race-share.interface';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  @Input() eventDetails!: RaceShareResponse;
  @Input() raceInfo !: RaceInfo;
  experienceOwnerName!: string;
  participantInfo!: ParticipantInfo;
  profileInitials!: string;
  participantProfileImage: any;
  isBestTime: boolean = false;
  subType!: string;
  itemType!: string;
  raceName!: string;
  lapCount!: number;
  isDriftChallenge!: boolean;
  exercisename: string ='';
  isSeconds: boolean = false;
  raceTime!: string;
  isOffroad: boolean = false;
  isTrackRace: boolean = false;
  translationData: any;

  constructor(private translationService: TranslationService){}
  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.raceName = this.raceInfo.raceName ? this.raceInfo.raceName : '';
    this.lapCount = this.raceInfo.maxValues.lapCount
    this.itemType = this.eventDetails.itemType;
    this.isSeconds = this.eventDetails.raceInfo.raceTime < 1000;
    this.participantInfo = this.eventDetails.participantInfo;
    if(this.participantInfo?.nickName) this.experienceOwnerName = this.participantInfo?.nickName;
    else this.experienceOwnerName = this.participantInfo?.firstName + " " + this.participantInfo?.lastName;
    this.profileInitials = this.participantInfo.firstName.charAt(0).toUpperCase() + this.participantInfo?.lastName.charAt(0).toUpperCase();
    this.participantProfileImage = this.participantInfo.profileImage;
    this.subType = this.eventDetails.subType;
    let bestTime = this.raceInfo.maxValues.bestTime;
    let timeObjhours = Math.floor((bestTime / (60 * 60)) % 24);
    let hours = timeObjhours > 0 ? timeObjhours.toString() : '';
    let timeObjmin = Math.floor((bestTime / 60) % 60);
    let minute = timeObjmin.toString(); // No leading zero for minutes
    let timeObjsec = Math.floor(bestTime % 60);
    let seconds = timeObjsec < 10 ? '0' + timeObjsec : timeObjsec.toString();
    let timeObjmilliseconds = bestTime % 60;
    let millisecondsArray = timeObjmilliseconds.toFixed(2).toString().split('.');
    let millisecond = millisecondsArray[1] ? millisecondsArray[1] : '00';
    this.raceTime =  minute + ':' + seconds + '.' + millisecond;
    this.isOffroad = this.itemType === 'OFFROAD' ? true: false
    this.isTrackRace = this.itemType === 'TRACKRACE' ? true: false


    this.isBestTime = (this.eventDetails?.eventInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME') || this.eventDetails?.exerciseInfo?.exerciseType?.includes('BESTTIME')) ?? false;
    this.isDriftChallenge = (this.eventDetails?.eventInfo?.exerciseInfo?.exerciseType?.includes('DRIFT') || this.eventDetails?.exerciseInfo?.exerciseType?.includes('DRIFT')) ?? false;
    this.getVisibilityData();

  }

  getVisibilityData(){
    switch(this.subType){
      case 'PERSONAL': {
        this.exercisename = this.eventDetails.itemType === 'OFFROAD' ? 'Offroad' : this.eventDetails?.exerciseInfo?.exerciseName ? this.eventDetails?.exerciseInfo?.exerciseName : '';
        return;
      }

      case 'EVENT':{
        this.exercisename = this.eventDetails?.exerciseInfo?.exerciseName ? this.eventDetails?.exerciseInfo?.exerciseName : '';
        return;
      }

     default:{
        this.exercisename = this.eventDetails?.exerciseInfo?.exerciseName ? this.eventDetails?.exerciseInfo?.exerciseName : '';
      }
    }

  }
}
