import { Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { Subject, concat, concatMap, Subscription, filter, forkJoin, takeUntil } from 'rxjs';
import { CarGarageComponent } from '../../components/car-garage/car-garage.component';
import { MyStoriesService } from '../../services/my-stories.service';
import { UserInfo } from 'src/app/components/interfaces/myStories.interface';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-my-stories-garage',
  templateUrl: './my-stories-garage.component.html',
  styleUrls: ['./my-stories-garage.component.scss']
})
export class MyStoriesGarageComponent implements OnInit {
  isShowSpinner: boolean = false;
  carsDetails: any;
  profileDetails!: UserInfo;
  storiesCount: any;
  isOwner: boolean = false;
  ciamId: any = '';
  vinOrFinFromRoute: any;
  shareProfileUrl: any;
  totalPages = 0;
  public page: any = 0;
  @ViewChild(CarGarageComponent) CarGarageComponent: any;
  vehicleVinOfCar: any;
  selectedLanguage = "en-GB";
  scrollEvent: any = 0;
  ProfileStickyInitiate = 160;
  isProfileClicked: boolean = false;
  isStoriesSection: boolean = false;
  // private unsubscribe$ = new Subject<void>();
  popupVisible: boolean = false;
  private subscription: Subscription;
  isMinCardActive: boolean = false;
  language = localStorage.getItem('language') || 'en-GB';
  public isPageNotFound: boolean = false;
  showErrorPage: boolean = false;
  @HostListener("window:scroll", ['$event'])
  onScroll(_event?: Event): void {
    const footer = document.getElementById('footer-container');
    if (window.scrollY >= 320) {
      this.isMinCardActive = true;
    }
    else {
      this.isMinCardActive = false;
    }
    if (footer) {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - footer.offsetHeight) {
        if (this.page < this.totalPages - 1) {
          this.loadMoreEventsButton();
        }
      }
    }

  }

  public loadMoreEventsButton(): void {
    this.page += 1;
    this.CarGarageComponent.getStroiesForCar(this.vehicleVinOfCar, this.page);
  }


  constructor(private myStoriesService: MyStoriesService,
    private awsAuth: AwsAuthService, private route: ActivatedRoute, private router: Router,
    private _languageService: LanguageService) {
    this.language = this.route.snapshot.paramMap.get('languageId') || this.language; // Provide a default value of an empty string if languageId is null
    if (this._languageService.checkIsValideLanguage(this.language)) {
      this.isPageNotFound = false
    } else {
      this.isPageNotFound = true;
      this.isShowSpinner = true;
    }
    this.subscription = this.myStoriesService.editProfileClicked$.subscribe(visible => {
      this.popupVisible = visible;
      this.isStoriesSection = true;
    });

    this.myStoriesService.editProfileClosed$.subscribe(closed => {
      if (closed) {
        this.popupVisible = false; // Hide popup
      }
    });
  }

  public ngOnInit(): void {
    if (!this.isPageNotFound) {
      this.route?.params.subscribe(
        (params: any): void => {
          this.ciamId = params['ciamId'] ? params['ciamId'] : '';
          this.vinOrFinFromRoute = params['vinOrFin'];
        }
      );
      let userLoggedIn = this.awsAuth.getUserLoggedInStatus();
      if (!this.ciamId && !this.vinOrFinFromRoute && !userLoggedIn) {
        this.selectedLanguage = localStorage.getItem('language') || 'en-GB';
        localStorage.setItem('routeUrl', this.router.url);
        localStorage.removeItem('currentCIAMUser')
        localStorage.removeItem('token')
        this.getAccessToken();
      } else {
        localStorage.setItem('routeUrl', this.router.url);
        this.fetchProfileAndGarageDetails(this.ciamId);
      }
    }
    this.router.events.subscribe((events) => {
      if (events instanceof NavigationEnd) {
        if (events.url === "/" + this.selectedLanguage + "/stories") {
          this.isShowSpinner = false;
          this.ciamId = "";
          this.carsDetails = '';
          this.vinOrFinFromRoute = "";
        }
        this.fetchProfileAndGarageDetails(this.ciamId);
      }
    })

  }



  cancelClicked() {
    // this.myStoriesService.editProfilePopupVisible(false);
    this.myStoriesService.editProfilePopupClosed();
  }

   public getAccessToken(): void {
      this.awsAuth.currentSession()?.then(
        (_res) => {
          let accessToken = _res.getAccessToken();
          let jwt = accessToken.getJwtToken();
          let jwt_expires_in = accessToken.getExpiration();
          let refreshToken = _res.getRefreshToken();
          let jwtRefreshToken = refreshToken.getToken();
          let idToken = _res.getIdToken();
          let jwtIdToken = idToken.getJwtToken();
          let fullData = {
            id_token: jwtIdToken,
            access_token: jwt,
            refresh_token: jwtRefreshToken,
            expires_in: jwt_expires_in,
            token_type: 'Bearer',
          };
          this.awsAuth.setUserLoginStatus(true);
          this.awsAuth.setCurrentUserToken(fullData);
          localStorage.setItem('currentCIAMUser', JSON.stringify(fullData));
          this.awsAuth.setUserLoggedInStatus();
          this.fetchProfileAndGarageDetails(this.ciamId);
        },
        (err: HttpErrorResponse) => {
          //
          this.awsAuth.signIn();
        }
      );
    }


  public showSpinner(): void {
    if (this.profileDetails && this.carsDetails) {
      this.isShowSpinner = true;
    } else if (!this.profileDetails) {
      this.isShowSpinner = true;
      this.showErrorPage = true;
    }
  }

  public totalNoOfPages(count: any): void {
    this.totalPages = count;
  }

  vehicleVin(vinOrFin: any) {
    this.vehicleVinOfCar = vinOrFin
    this.page = 0;
  }

  /**
   * @description Fetches the profile and garage details
   * @param ciamId
   * @returns void
   */
  public fetchProfileAndGarageDetails(ciamId: string): void {
    this.showErrorPage = false
    this.myStoriesService.getProfile(ciamId).pipe(concatMap((profileDetails: any) => {
      this.profileDetails = profileDetails;
      return this.myStoriesService.getGarageDetails(ciamId);
    })).subscribe(
      (garageDetails: any) => {
        this.carsDetails = garageDetails;
        this.shareProfileUrl = garageDetails.shareProfileUrl;
        this.isOwner = garageDetails.isOwner;
        this.showSpinner();
      },
      (error: HttpErrorResponse) => {
        if (error.error['error-code'] === '9012'|| error.error['error-code']==='2005'){
          this.router.navigateByUrl('/'+this.language+'/404');
        } else if (error.error['error-code'] === '8001') {
          this.showSpinner();
        }

      });
  }
}

